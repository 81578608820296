@import '../../../colors';

.product-category {
  background-color: $light-gray;
  h3 {
    font-size: 2rem;
    padding: 10px;
  }
  .product {
    margin: 20px 10px;
    height: 300px;
  }
  .background {
    -webkit-background-size: 100%;
    background-size: 100% !important;
    height: 100%;
    border: 1px solid #ddd;
  }
  .solid-chip {
    background: url("../../../assets/images/products/solid-chip-type/solid-chip.png") no-repeat center white;
  }
  .solid-lead {
    background: url("../../../assets/images/products/solid-lead-type/solid-lead.png") no-repeat center white;
  }
  .chip-type {
    background: url("../../../assets/images/products/chip-type/chip.png") no-repeat center white;
  }
  .miniature {
    background: url("../../../assets/images/products/miniature/miniature.png") no-repeat center white;
  }
  .high-rip {
    background: url("../../../assets/images/products/high-rip/high-rip.png") no-repeat center white;
  }
  .snap-in {
    background: url("../../../assets/images/products/snap-in-terminal/snap-in.png") no-repeat center white;
  }
  .special-app {
    background: url("../../../assets/images/products/special-app/special-app.png") no-repeat center white;
  }
  .hybrid {
    background: url("../../../assets/images/products/hybrid/hybrid.png") no-repeat center white;
  }
}

.product-category
  .title {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  color: white;
  position: absolute;
  top: 0;
  font-size: 1.3rem;
}

//individual product category page
.all-product-header {
  background-color: $dark-gray;
  height: auto;
  padding: 30px;

  h1 {
    color: white;
    font-size: 3em;
  }
}

@media screen and (max-width: 991px) {
  .product-category {
    .title {
      font-size: 1em;
    }
  }
}
