$navbar-inverse-link-color: #5AADBB;
$main-green: #018B3E;
$dark-gray: #363636;
$light-gray: #F2F2F2;
$text-color: #999999;
$icon-font-path: "../../bootstrap-sass/assets/fonts/bootstrap/";

$bg: #fffff0;
$line: #BADA55;
$red: #f98262;
$date: black;