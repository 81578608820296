@import '../../colors';

.btn-learn {
  font-size: 1em;
  color: white;
  border: 2px solid white;
  background-color: transparent;
  padding: 10px;
  margin: 20px;
  letter-spacing: 1px;
  box-sizing: border-box;
  &:hover {
    color: $main-green;
    border: 2px solid $main-green;
    text-decoration: none;
  }
}

.send-btn {
  background-color: $main-green !important;
  color: white !important;
}

@media (max-width: 767px) {
  .btn-learn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 100;
    margin: -100px auto 0 auto;
    background-color: rgba(0, 0, 0, 0.7);
  }
}