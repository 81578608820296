@import '../../../colors';

.navbar {
  background-color: $main-green;
  a {
    display: inline-block;
    color: white;
    text-decoration: none;
    font-size: 1.25rem;
  }

  a:hover {
    color: black;
  }
}

#responsive-navbar-nav {
  height: 100%;
}

.navbar-expand-xl {
  .navbar-collapse {
    justify-content: center;
    align-items: center;
  }
}

.navbar-light {
   .navbar-nav {
      .nav-link {
      color: white !important;
      margin-right: 55px;

      &:active {
        color: rgba(0, 0, 0, .7);
      }
    }
  }
  .navbar-toggler {
    margin: 0 auto;
    border-color: black !important;
  }
}

@media screen and (max-width: 1200px) {
  .navbar {
    padding: 10px 0;
  }

  .navbar-light {
    .navbar-nav {
      a {
        margin: 10px auto;
        display: block;
      }
    }
  }
}