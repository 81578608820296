@import '../../../../colors';

.thumbnail {
  padding: 20px 10px;
  line-height: 1.5em;
  margin: 10px;
  background-color: white;
  border: 1px solid #ddd;
  height: 100%;
  overflow: hidden;
  img {
    width: 100px;
    height: 100px;
  }
  ul > li {
    margin: 10px;
  }
  .btn-learn {
    color: black;
    border: 2px solid black;
    background-color: transparent;
    padding: 5px 10px;
    margin: 20px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    box-sizing: border-box;
  }
  .btn-learn:hover {
    background-color: $dark-gray;
    border: 2px solid white;
    color: white;
  }
}

@media screen and (max-width: 991px) {
  .thumbnail {
    min-height: 506px
  }
}
