#contact-form {
    .send-btn {
      margin: 0 auto;
      display: block;
    }
    input {
        height: 60px;
    }
    .thank-msg {
      font-size: 25px;
    }
  }
  