.footer {
  background: url('../../assets/images/backgrounds/footer-background.png') fixed #37393a;
  -webkit-background-size: contain;
  background-size: contain;
  border-top: 1px solid #787878;
  color: white;
  padding: 30px;

  h5 {
    color: #018B3E;
    margin-bottom: 20px;
    font-size: 2em;
  }

  p.address {
    font-size: 1.3em;
  }

  p.phone, p.footer-note {
    margin-top: 30px;
    font-size: 1em;
  }
}

.footerNav {
  background-color: #283136;
  padding: 20px 0;
  a {
    color: white;
  }
  a:hover {
    color: #018B3E;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    h5 {
      font-size: 1.5em;
    }
    p.address, p.phone, p.footer-note {
      font-size: 0.8em;
      margin-top: 10px;
    }
  }
}