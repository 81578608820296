@import '../../colors';

#contact-page {
  .office-section {
    padding: 50px;
    box-sizing: border-box;
    background-color: #F2F2F2;
  }
}

@media screen and (max-width:991px) {
  #contact-page {
    .office-section {
      padding: 40px 20px 20px;
    }
  }
}