@import '../../colors';

.text-box {
  z-index: 15;
  position: absolute;
  width: 400px;
  background-color: rgba(0,0,0, 0.7);
  bottom: 20%;
  left: 25%;
  color: $main-green;
  font-size: 1.2rem;
  font-weight:bold;
  letter-spacing: 2px;
  line-height: 30px;
  padding: 1.5rem;
  text-align: center;
}
