@import '../../colors';

#product-page {
  .product-finder {
    padding: 3rem;
    .btn-learn {
      color: $dark-gray;
      border: 2px solid $dark-gray;
      background-color: transparent;
      padding: 15px;
      margin: 10px;
    }
    .btn-learn:hover {
      background-color: $dark-gray !important;
      border: 2px solid white;
      color: white;
    }
  }
}

@media screen and (max-width: 767px) {
  #product-page {
    .product-finder {
      h3 {
        font-size: 1.3em;
      }
      h5 {
        font-size: 1em;
      }
      .btn-learn {
        margin-bottom: 0;
      }
    }
  }
}

