.banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
  color: white;

  &.product-banner {
    background-image: url('../../assets/images/backgrounds/product-cover-dark.jpg');
  }

  &.facility-banner {
    background-image: url('../../assets/images/backgrounds/join-the-team-dark.jpg');
  }

  &.contact-banner {
    background-image: url('../../assets/images/backgrounds/factory-3.jpg');
  }

  h2 {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 767px) {
  .banner {
    height: 200px;
    h1 {
      font-size: 1.5em;
    }
    h2 {
      font-size: 0.8em;
    }
  }
}