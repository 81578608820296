@import '../../colors';

#home {
  h1, h2, h3  {
    margin: 30px 0;
    font-style: italic;
  }

  .carousel {
    width: 100%;
    overflow: hidden;
    height: 600px;
    position: relative;
    z-index: 1;
    img {
      margin: 0 auto;
      height: 600px;
    }
    .carousel-control-next-icon, .carousel-control-prev-icon {
      width: 50px;
      height: 50px;
    }
    .carousel-indicators li {
      height: 10px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  .about {
    padding: 3rem;
    h1 {
      margin-bottom: 50px;
    }
    p {
      line-height: 2em;
      color: #999999;
      font-size: 1.5em;
    }
  }

  .history {
    background-color: $light-gray;
    padding: 3rem;
    h2 {
      font-size: 2.5rem;
    }
    ul {
      line-height: 2em;
      color: #787878;
    }
  }

  .certificate {
    padding: 3rem;
    h3 {
      margin-bottom: 20px;
      font-size: 2.5rem;
    }
    h4 {
      margin-bottom: 50px;
      color: #999999;
    }
    .btn-learn {
      color: #363636;
      border: 2px solid #363636;
      background-color: initial;
      padding: 15px;
      margin: 10px auto;
      min-width: 300px;
    }
    .btn-learn:hover {
      background-color: $dark-gray;
      border: 2px solid white;
      color: white;
    }
  }
}

@media screen and (max-width:767px) {
  #home {
    .certificate {
      padding: 10px;
    }
    h1, h2, h3 {
      font-size: 2em;
    }
    ol.carousel-indicators {
      display: none;
    }
    .carousel {
      height: 300px;
      display: flex;
      justify-content: center;
      img {
        height: 300px;
      }
      .btn-learn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 150px;
        z-index: 100;
        margin: -100px auto 0 auto;
        background-color: rgba(0,0,0, 0.7);
      }
    }
    .about {
      padding: 10px;
      h1 {
        margin-bottom: 10px;
        font-size: 2em;
      }
      p {
        font-size: 1em
      }
    }
    .history {
      h2 {
        font-size: 2em;
      }
    }
    .certificate {
      h3 {
        margin-bottom: 10px;
        font-size: 2em;
      }
      h4 {
        margin-bottom: 30px;
        font-size: 1em
      }
    }
  }
}

