@import './colors';
@import '~bootstrap/scss/bootstrap';
@import "~font-awesome/css/font-awesome.min.css";


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.25em;
  overflow-x: hidden;
  color: black
}

h1, h2 {
  color: $dark-gray;
  letter-spacing: 3px;
}

h3, h4 {
  letter-spacing: 2px;
}

a {
  text-decoration: none;
}

a:hover, a:focus, button {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active {
  background-color: $dark-gray;
}

.main-green {
  color: $main-green;
}

.bg-main-green {
  background-color: $main-green;
}

.white {
  color: white;
}

.dark-gray {
  color: $dark-gray
}

.fs-26 {
  font-size: 26px;
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 2em;
  }
}