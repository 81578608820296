  .vertical-timeline {
    width: 100%;
  }
  .vertical-timeline--two-columns
  .vertical-timeline-element-content
  .vertical-timeline-element-date {
    color: white;
    font-weight: bold;
    right: 110% !important;
    opacity: 1;
  }

  .vertical-timeline--two-columns
  .vertical-timeline-element.vertical-timeline-element--right
  .vertical-timeline-element-content
  .vertical-timeline-element-date {

  }

@media only screen and (max-width: 1123px) {
      .vertical-timeline--two-columns
        .vertical-timeline-element-content
          .vertical-timeline-element-date {
        left: -6.6%;
        top: -3px;
        position: absolute;
      }
  }

@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns
  .vertical-timeline-element-content
  .vertical-timeline-element-date {
    left: 110%;
  }
}


@media only screen and (max-width: 991px) {
    .vertical-timeline--two-columns
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
      left: -9%;
      position: absolute;
      top: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .vertical-timeline--two-columns
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
      left: -10%;
      position: absolute;
      top: 0;
    }
  }

@media only screen and (max-width: 767px) {
    .vertical-timeline--two-columns
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
      left: -23%;
      position: absolute;
      top: 0;
    }
}

@media only screen and (max-width: 414px) {
  .vertical-timeline--two-columns
  .vertical-timeline-element-content
  .vertical-timeline-element-date {
    left: -24%;
    position: absolute;
    top: 0;
  }
}

@media only screen and (max-width: 390px) {
  .vertical-timeline--two-columns
  .vertical-timeline-element-content
  .vertical-timeline-element-date {
    left: -27%;
    position: absolute;
    top: 0;
  }
}

@media only screen and (max-width: 376px) {
    .vertical-timeline--two-columns
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
      left: -29%;
      position: absolute;
      top: 0;
    }
}